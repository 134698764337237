body{
  background-color: #000000!important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.flex-container {
	width: 35vw;
	margin: auto;
	margin-top: 10vw;
	display: flex;
	flex-flow: row wrap;
	border: 2px solid #0f1722;
	border-radius: 10px;
	background-color: #131313;
}

.flex-container>div {
	color: whitesmoke;
	/* width: 100px; */
	margin: 10px;
	text-align: center;
	font-size: 30px;
}

.flex-container>div>p {
	padding-top: 10px;
	padding-bottom: 10px;
	line-height: 25px;
}

